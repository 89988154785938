export const GeocoderInputKeys = { GeocoderOriginInput: "GeocoderOriginInput", GeocoderDestinationInput: "GeocoderDestinationInput" };
export const regionsGermany = {
    'rlp': ["Rheinland-Pfalz", "Rheinland Pfalz"],
    'nw': ["Nordrhein-Westfalen", "Nordrhein Westfalen"],
    'he': ["Hessen"],
    'be': ["Berlin"],
    'by': ["Bayern"],
    'bw': ["Baden-Württemberg", "Baden Württemberg"],
    'bb': ["Brandenburg"],
    'hb': ["Bremen"],
    'hh': ["Hamburg"],
    'mv': ["Mecklenburg-Vorpommern", "Mecklenburg Vorpommern"],
    'ni': ["Niedersachsen"],
    'sl': ["Saarland"],
    'sh': ["Schleswig Holstein"],
    'th': ["Thüringen"]
}

export const TransportTypesArray = [
    'rideAndBikesharing',
    'rideAndBike',
    'carsharing',
    'rideAndCarsharing',
    'rideAndCar',
    'bikesharing',
    'bike',
    'car',
    'eCar',
    'eBike',
    'rideAndTaxi',
    'walk',
    'train',
    'trainRegio',
    'taxi'
];

// lists the available icons of compounds. FIXME: should be retrieved automatically by Backend
export const availableCompoundsIcons = ["avv", "db", "gvh", "mvv", "nph", "nvv", "nwl", "rmv", "rnn", "vbn",
    "mdv",
    "vrl", "vrn", "vrr", "vrs", "vvs", "naldo"];